<template>
  <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
    <Search
      codeKey="euc_search_vdn_type"
      :searchObj="searchType.obj"
      :useKeywordType="true"
      @change-search-type="$_changeSearchType"
      @onSearchClick="$_searchData($event, 'euc_search_vdn_type')"
    >
      <template v-slot:before>
        <DxSelectBox
          v-model="searchType.customTypes.vdnMenuCd"
          :items="getSearchType"
          display-expr="codeNm"
          value-expr="id"
          :styling-mode="stylingMode"
          :width="120"
          :height="30"
          @value-changed="$_changeSearchCustomType('vdnMenuCd', $event)"
        />
      </template>
    </Search>
    <esp-dx-data-grid :data-grid="dataGrid" ref="vdnGrid"></esp-dx-data-grid>
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { isSuccess } from '@/plugins/common-lib';
  import { DxSwitch } from 'devextreme-vue/switch';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm;

  export default {
    components: {
      EspDxDataGrid,
      Search,
      DxSelectBox,
    },
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            vdnMenuCd: null,
          },
        },
        config: {
          pageSetting: {
            config: {},
            formData: {},
          },
          stylingMode: 'outlined',
        },
        dataGrid: {
          refName: 'vdnGridRefName',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          apiActionNm: {
            update: 'VDN_LIST_UPDATE',
            delete: 'VDN_LIST_DELETE',
          },
          showActionButtons: {
            update: true,
            delete: true,
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '교환기명',
              dataField: 'cmNm',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              fixed: false,
              fixedPosition: 'left',
            },
            {
              caption: 'VDN명',
              dataField: 'vdnNm',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              fixed: false,
              fixedPosition: 'left',
            },
            {
              caption: 'VDN번호',
              dataField: 'ext',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              fixed: false,
              fixedPosition: 'left',
            },
            {
              caption: 'MEAS',
              dataField: 'meas',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              fixed: false,
              fixedPosition: 'left',
            },
            {
              caption: '메뉴명',
              dataField: 'vdnMenuCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const value = options.value === null ? 0 : options.value;
                const selectbox = new DxSelectBox({
                  propsData: {
                    placeholder: '선택',
                    items: this.$_getCode('euc_vdn_menu'),
                    displayExpr: 'codeNm',
                    valueExpr: 'id',
                    value: value,
                    stylingMode: this.config.stylingMode,
                    onValueChanged: e => {
                      const value = e.value === null ? 0 : e.value;
                      this.$refs.vdnGrid.getGridInstance.cellValue(options.rowIndex, options.columnIndex, value);
                    },
                  },
                });
                selectbox.$mount();
                container.append(selectbox.$el);
              },
            },
            {
              caption: 'VDN 코드',
              dataField: 'vdnTypeCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const value = options.value === null ? 0 : options.value;
                const selectbox = new DxSelectBox({
                  propsData: {
                    placeholder: '선택',
                    items: this.$_getCode('euc_vdn_type'),
                    displayExpr: 'codeNm',
                    valueExpr: 'id',
                    value: value,
                    stylingMode: this.config.stylingMode,
                    onValueChanged: e => {
                      const value = e.value === null ? 0 : e.value;
                      this.$refs.vdnGrid.getGridInstance.cellValue(options.rowIndex, options.columnIndex, value);
                    },
                  },
                });
                selectbox.$mount();
                container.append(selectbox.$el);
              },
            },
            {
              caption: '타이틀',
              dataField: 'title',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              fixed: false,
              fixedPosition: 'left',
            },
            {
              caption: '설명',
              dataField: 'description',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              fixed: false,
              fixedPosition: 'left',
            },
            {
              caption: '순서',
              dataField: 'vdnOrd',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              fixed: false,
              fixedPosition: 'left',
            },
            {
              caption: '사용 여부',
              dataField: 'viewCd',
              width: 120,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.value === 1,
                    onValueChanged: e => {
                      this.onChangedViewCd(options.data, e.value);
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
            },
            {
              caption: '등록일시',
              dataField: 'regDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.regDt,
            },
          ],
        },
      };
    },
    computed: {
      getSearchType() {
        return [{ id: null, codeNm: '전체' }, ...this.$_getCode('euc_vdn_menu')];
      },
    },
    methods: {
      async onChangedViewCd(data, value) {
        if (data.id) {
          const payload = {
            actionname: 'VDN_LIST_UPDATE',
            data: {
              data: [
                {
                  id: data.id,
                  viewCd: value ? this.$_getUsedCode.id : this.$_getUnUsedCode.id,
                },
              ],
            },
            loading: false,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$refs.vdnGrid.getGridInstance.refresh();
          }
        }
      },
      async selectDataList() {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = '+vdnOrd';
            }

            params = { ...params, ...vm.searchType.paramsData };

            const payload = {
              actionname: 'VDN_LIST_ALL',
              data: { params },
              loading: true,
              useErrorPopup: true,
            };

            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              const resultData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
              return resultData;
            } else {
              return () => {
                throw 'Data Loading Error';
              };
            }
          },
        });
      },
      regDt(rowData) {
        if (rowData?.regDt) {
          return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD');
        }
      },
      createdData() {
        vm = this;
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.selectDataList();
    },
  };
</script>
